import React, { Component } from "react"
import PropTypes from "prop-types"
import { globalHistory, Router } from "@reach/router"
import Layout from "../components/layout/layout"
import CatalogListing from "../components/buy-equipment/catalog-listing"
import SEO from "../components/seo"

const Catalog = () => {
  // const { category } = search

  const parts = globalHistory.location.pathname
    .split("/")
    .slice(2) // Skip /catalog/ prefix.
    .map((s) =>
      s
        .replace(/-/g, " ")
        .trim()
        .replace(/\b\w/g, (c) => c.toUpperCase())
    )
  const category = parts.length > 0 ? parts[0] : "Equipment"
  const kind = parts.length > 1 ? parts[1] : category
  let title, description, pageDescription
  if (category === "New Arrivals" || category === "Featured") {
    title = category
    description = ""
  } else {
    title = `Used ${kind}`
    description = `Buy and Sell Used ${category} from Surplus Solutions. SSLLC offers a large Inventory of ${title} from top OEMs.`
  }
if (category === "Featured") {
  pageDescription = (
    <>
      Check out our featured biotech equipment from a world-class San Diego site closure. Request a quote now or register for the upcoming premier auctions and try your luck bidding at this <a href="/auctions">site</a>.
    </>
  );
}


  return (
    <Layout>
      <SEO title={title} description={description} />

      <div className="row">
        <div className="page-description">
          {pageDescription}
        </div>
        <Router>
          {/* <CategorySelection path="catalog/:rootCategory/:subCategory" />
          <CategorySelection path="catalog/:rootCategory" /> */}

          <CatalogListing path="catalog/:rootCategory" />
          <CatalogListing path="catalog/:rootCategory/:subCategory" />
          <CatalogListing path="catalog/:rootCategory/:subCategory/:subCategory2" />
        </Router>
      </div>
    </Layout>
  )
}

// const CategorySelection = props => (
//   <h2>
//     {props.rootCategory} - {props.subCategory}
//   </h2>
// )

export default Catalog
